import React, { FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import useVariants from 'hooks/useVariants';
import Button from 'common/Button';

import ProductVariant from './components/ProductVariant';
import { IProductVariants } from './models';

import './ProductVariants.scss';

const ProductVariants: FC<IProductVariants> = ({
  productVariants,
  productSize = '',
  productScent = '',
  scentLabel,
  sizeLabel,
  lang,
  isAlternativeStyle,
  sizesOpener,
  sizesOpenerAria,
  scentsOpener,
  scentsOpenerAria,
}) => {
  const [isSizesClosed, setIsSizesClosed] = useState(!isAlternativeStyle);
  const [isScentsClosed, setIsScentsClosed] = useState(!isAlternativeStyle);

  const { selected, sizes, scents, isDeadLock, clickHandler } = useVariants(
    productVariants,
    productSize,
    productScent
  );

  const sizesArray = useMemo(
    () =>
      Object.keys(sizes).sort((a, b) =>
        a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
      ),
    [sizes]
  );
  const scentsArray = useMemo(
    () => Object.keys(scents).sort((a, b) => a.localeCompare(b, lang.split('-')[0])),
    [scents, lang]
  );
  const handleSizesOpen = () => setIsSizesClosed(!isSizesClosed);
  const handleScentsOpen = () => setIsScentsClosed(!isScentsClosed);

  useEffect(() => {
    if (isAlternativeStyle && scentsArray?.length === 1) {
      clickHandler(scentsArray?.[0], 'scent');
    }
  }, [scentsArray]);
  useEffect(() => {
    if (isAlternativeStyle && sizesArray?.length === 1) {
      clickHandler(sizesArray?.[0], 'size');
    }
  }, [sizesArray]);
  useEffect(() => {
    setIsSizesClosed(false);
    setIsScentsClosed(false);
  }, [selected]);

  return (
    <div
      className={classnames('product-variants', {
        'product-variants--alternative': isAlternativeStyle,
      })}
      data-testid="product-variants"
    >
      <div className="product-variants__grid">
        {sizesArray.length ? (
          <div
            className={classnames(
              'product-variants__grid-item product-variants__grid-item--sizes',
              {
                'product-variants__grid-item--sizes-full': isDeadLock,
              }
            )}
          >
            {sizeLabel ? <strong className="product-variants__label">{sizeLabel}</strong> : null}
            {isAlternativeStyle ? (
              <Button
                aria-label={sizesOpenerAria}
                className={classnames('product-variants__opener', {
                  'product-variants__opener--select-style': sizesArray.length > 1,
                })}
                onClick={sizesArray.length > 1 && handleSizesOpen}
                {...(sizesArray.length > 1 && { iconSuffix: 'chevron-right' })}
                variant="secondary"
              >
                {selected?.size ? selected.size : sizesOpener}
              </Button>
            ) : null}

            <div
              className={classnames('product-variants__drop-down', {
                'product-variants__drop-down--opened': isSizesClosed && isAlternativeStyle,
              })}
            >
              {sizesArray.map((size) => (
                <ProductVariant
                  key={size}
                  label={size}
                  availableScents={sizes[size]}
                  type="size"
                  availableType="scent"
                  current={selected}
                  isDeadLock={isDeadLock}
                  onSelected={clickHandler}
                />
              ))}
            </div>
          </div>
        ) : null}
        {scentsArray.length ? (
          <div className="product-variants__grid-item product-variants__grid-item--scents">
            {scentLabel ? <strong className="product-variants__label">{scentLabel}</strong> : null}
            {isAlternativeStyle ? (
              <Button
                aria-label={scentsOpenerAria}
                className={classnames('product-variants__opener', {
                  'product-variants__opener--select-style': scentsArray.length > 1,
                })}
                onClick={scentsArray.length > 1 && handleScentsOpen}
                {...(scentsArray.length > 1 && { iconSuffix: 'chevron-right' })}
                variant="secondary"
              >
                {selected?.scent ? selected.scent : scentsOpener}
              </Button>
            ) : null}
            <div
              className={classnames('product-variants__drop-down', {
                'product-variants__drop-down--opened': isScentsClosed && isAlternativeStyle,
              })}
            >
              {scentsArray.map((scent) => (
                <ProductVariant
                  key={scent}
                  label={scent}
                  availableScents={scents[scent]}
                  type="scent"
                  availableType="size"
                  current={selected}
                  onSelected={clickHandler}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductVariants;
