import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { Container, DangerouslySetInnerHtml } from 'layout';
import { UmbracoProductBenefits } from '@shared/types/umbraco/compositions';
import Icon from 'common/IconCustom';
import GatsbyImage from 'common/GatsbyImage';
import getFamily from 'utils/getFamily';
import './ProductBenefits.scss';

const ProductBenefits: FC<UmbracoProductBenefits.IData> = ({ title, items, tags, isFiveInRow }) => {
  const family = getFamily(tags);

  return (
    <div
      className={classNames('product-benefits', {
        [`product-benefits--${family?.color?.label}-family`]: Boolean(family),
        [`product-benefits--long-row`]: isFiveInRow,
      })}
      data-testid="product-benefits"
    >
      <Container fluid>
        {title ? (
          <DangerouslySetInnerHtml element="h2" className="product-benefits__title" html={title} />
        ) : null}
        <div
          className={classNames('product-benefits__grid', {
            [`product-benefits--long-row`]: isFiveInRow,
          })}
        >
          {items?.length
            ? items.map(({ properties: { icon, imageIcon, imageAlt, description } }) => (
                <div key={description.length} className="product-benefits__item">
                  {imageIcon && imageIcon.fallbackUrl ? (
                    <GatsbyImage
                      image={imageIcon}
                      alt={imageAlt}
                      objectFit="contain"
                      className="product-benefits__item-icon"
                    />
                  ) : (
                    <Icon icon={icon?.[0]} />
                  )}
                  {description ? (
                    <DangerouslySetInnerHtml
                      className="product-benefits__description"
                      html={description}
                    />
                  ) : null}
                </div>
              ))
            : null}
        </div>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentBenefits on TBenefits {
    properties {
      showInMenu
      anchorName
      anchorTitle
      title
      isFiveInRow
      items {
        properties {
          description
          icon
          imageIcon {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fixed(width: 64) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          imageAlt
          iconAlignment
        }
      }
    }
    structure
  }
`;

export default ProductBenefits;
