import React, { FC } from 'react';
import classNames from 'classnames';
import { BazaarRatingSummary } from '@phx-husky/bazaarvoice-components';
import { ProductPageTypes } from '@shared/types/umbraco/content/productPage';

import { Container, DangerouslySetInnerHtml } from 'layout';
import BreadCrumbs from 'common/BreadCrumbs';
import WTBCards from 'components/WTBCards';
import PDCarousel from 'components/PDCarousel';
import GatsbyImage from 'common/GatsbyImage';
import ProductVariants from 'components/ProductVariants';
import Button from 'common/Button';
import AddToCartComponent from 'common/AddToCartComponent';

import useScreenRecognition from 'hooks/useScreenRecognition';
import getFamily from 'utils/getFamily';
import getBreadCrumbsTheme from 'utils/getBreadCrumbsTheme';
import transformProductTitle from 'utils/stringUtils/transformProductTitle';
import transformProductSubtitle from 'utils/stringUtils/transformProductSubtitle';
import { scrollToSection } from 'utils/browser';

import './ProductDetails.scss';

const ProductDetails: FC<ProductPageTypes.IProductDetails> = ({
  skuId,
  shopifyId,
  cartProductsData,
  title,
  bgImage,
  shortDescription,
  tags,
  breadCrumbs,
  availableLabel,
  availableLinks,
  images,
  productVariants,
  scentLabel,
  sizeLabel,
  productSize,
  productScent,
  packagingType,
  productsList,
  waiSettings,
  isShopify,
  lang,
  masterProduct,
  subtitlePlaceholder = '',
  usePriceSpider,
  ean,
  bvScript,
  bvProductId,
  showBazaarvoiceReviewCta,
  bazaarvoiceSeeReviewLabel,
}) => {
  const family = getFamily(tags);
  const bcTheme = getBreadCrumbsTheme(tags);
  const { isDesktop } = useScreenRecognition();

  const BundleProductsListElement = () =>
    productsList?.length ? (
      <ul className="product-details__products-list">
        {productsList.map((el) => (
          <li key={el}>
            <bdi>{el}</bdi>
          </li>
        ))}
      </ul>
    ) : null;

  const subtitle = transformProductSubtitle({
    subtitlePlaceholder,
    productSize,
    packagingType,
    productScent,
  });

  return (
    <div
      className={classNames('product-details', {
        [`product-details--${family?.color?.label}-family`]: Boolean(family),
      })}
      data-testid="product-details"
    >
      <Container fluid>
        <div className="product-details__grid">
          <div
            className={classNames(
              'product-details__text',
              `product-details__text--${bcTheme}-theme`
            )}
          >
            {!!breadCrumbs?.length && (
              <BreadCrumbs
                data={breadCrumbs}
                popIndex={masterProduct?.length ? 2 : 1}
                theme={bcTheme}
              />
            )}
            {title ? (
              <h1 className="product-details__title">
                <DangerouslySetInnerHtml element="span" html={title} />
                {subtitle && <span className="product-details__title--sub">{subtitle}</span>}
              </h1>
            ) : null}
            {shortDescription ? (
              <DangerouslySetInnerHtml
                className="product-details__description"
                html={shortDescription}
              />
            ) : null}
            {isDesktop ? <BundleProductsListElement /> : null}
            {bvScript ? <BazaarRatingSummary productId={bvProductId} /> : null}
          </div>
          <div
            className={classNames(
              'product-details__controls',
              `product-details__controls--${bcTheme}-theme`
            )}
          >
            {!isDesktop ? <BundleProductsListElement /> : null}
            {!productsList?.length && productVariants?.nodes?.length ? (
              <ProductVariants
                {...{ productVariants, productSize, productScent, scentLabel, sizeLabel }}
                lang={lang}
              />
            ) : null}
            <div className="product-details__cta-block">
              {ean && !!usePriceSpider && (
                <div className="ps-wrapper">
                  <div className="ps-widget" ps-sku={ean} />
                </div>
              )}
              {showBazaarvoiceReviewCta && bazaarvoiceSeeReviewLabel ? (
                <Button
                  ariaLabel={bazaarvoiceSeeReviewLabel}
                  variant="secondary"
                  classes="product-details__see-reviews--button"
                  clickHandler={scrollToSection('bv-reviews')}
                >
                  {bazaarvoiceSeeReviewLabel}
                </Button>
              ) : null}
            </div>
            {availableLinks?.length ? <WTBCards {...{ availableLabel, availableLinks }} /> : null}
            <AddToCartComponent
              skuId={skuId}
              shopifyId={shopifyId}
              cartProductsData={cartProductsData}
              isShopify={isShopify}
              lang={lang}
              productTitle={transformProductTitle({
                productTitle: title,
                productSize,
                packagingType,
                productScent,
                withBracket: false,
              })}
            />
          </div>
          <div
            className={classNames(
              'product-details__media',
              `product-details__media--${bcTheme}-theme`
            )}
          >
            {images?.length ? (
              <PDCarousel
                classes="product-details__carousel"
                slides={images}
                ariaNext={waiSettings?.ariaNext}
                ariaPrev={waiSettings?.ariaPrev}
              />
            ) : null}
            {bgImage ? (
              <GatsbyImage
                image={bgImage}
                role="presentation"
                className="product-details__image-bg"
                alt=""
                isLazyLoading={false}
                fadeIn={false}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProductDetails;
